<template>
  <div> 
    <!-- Backend Errors -->
    <div v-if="isError === true">
      <div v-for="error in errors" :key="error">
        <div v-for="el in error" :key="el">
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-text color="danger">{{el}}</ion-text>
          </ion-item>
        </div>
      </div>
    </div>
    <!-- Frontend Errors -->
    <ion-item v-if="currentPasswordFailed === true">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-text color="danger">The password entered is incorrect.</ion-text>
    </ion-item>
    <ion-item v-if="passwordMismatch === true">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-text color="danger">Both password fields must match</ion-text>
    </ion-item>
    <ion-item v-if="passwordLengthErr === true">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-text color="danger">Password must be between 8 and 24 characters</ion-text>
    </ion-item>
    <!-- Current Password -->
    <div v-if="currentPasswordValidated === false">
      <!-- Input -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-label position="stacked">Current Password</ion-label>
        <ion-input v-model="currentPassword" type="password" placeholder="Enter your current password"></ion-input>
      </ion-item>
      <!-- Submit -->
      <ion-item v-if="currentPassword != null && currentPassword != ''">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-button @click.prevent="validateCurrentPassword()">Submit</ion-button>
      </ion-item>
    </div>
    <!-- New Password -->
    <div v-else>
      <!-- Input -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-label position="stacked">New Password</ion-label>
        <ion-input v-model="newPassword" type="password" placeholder="Enter your new password"></ion-input>
      </ion-item>
      <!-- Input -->
      <ion-item>
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-label position="stacked">Confirm New Password</ion-label>
        <ion-input v-model="confirmPassword" type="password" placeholder="Confirm your new password"></ion-input>
      </ion-item>
      <ion-button v-if="newPasswordValidated === true" color="primary" @click.prevent="storeNewPassword()">Save</ion-button>
      <ion-button color="medium" @click.prevent="resetForm()">Reset</ion-button>
    </div>    
  </div>
</template>

<script>
import { IonLabel, IonInput, IonItem, IonButton, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
//import { person } from 'ionicons/icons';
import apiClient from '../services/api';

export default defineComponent({
  name: 'ProfileSecurity',
  props: ['store'], 
  components: {
    IonLabel, IonInput, IonItem, IonButton
  },
  setup(props) {
    const isError = ref(false);
    const errors = ref([]);    
    const currentPassword = ref(null)
    const newPassword = ref(null)
    const confirmPassword = ref(null)
    const currentPasswordValidated = ref(false)
    const currentPasswordFailed = ref(false)
    const newPasswordValidated = ref(false)
    const passwordMismatch = ref(false)
    const passwordLengthErr = ref(false)
    const timeout = { default: 1000 }
    const toastMessage = ref(null);

    async function presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
          duration: timeout,
        });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
        .create({
          //message: '',
          duration: 6000,
          message: toastMessage.value,
          position: 'bottom',
          buttons: [
            {
              icon: 'close',
              role: 'cancel',
              handler: () => {
                
              }
            }
          ]
        })
      return toast.present();
    }

    function validateCurrentPassword() {
      presentLoading()
      apiClient.post('/api/validate/password', 
        {
          password: currentPassword.value           
        },
        {
          headers: {
            'Authorization':  `Bearer ${props.store.authToken}`
          }
        }).then(response => {
          console.log(response)
          if(response.data === 1) {
            currentPasswordValidated.value = true
            currentPasswordFailed.value = false
          } else {
            currentPasswordValidated.value = false
            currentPasswordFailed.value = true
          }
        }).catch(error => {
          console.log(error)  
          // credentials didn't match

          //this.isError = true
          //this.errors = error.response.data.errors

        });      
    }

    function resetForm() {
      currentPasswordFailed.value = false
      currentPasswordValidated.value = false
      currentPassword.value = null
      newPassword.value = null
      confirmPassword.value = null
      passwordMismatch.value = false
      passwordLengthErr.value = false
      newPasswordValidated.value = false
      isError.value = false
      errors.value = []
    }

    function validateNewPassword() {
      var passwordOneLengthErr = false
      var passwordTwoLengthErr = false

      if(newPassword.value != null) {
        // length
        if(newPassword.value.length >= 8 && newPassword.value.length <= 24) {
          passwordOneLengthErr = false
        } 
        // Password is not the right length
        else {
          passwordOneLengthErr = true
        }
      }

      if(confirmPassword.value != null) {
        // length
        if(confirmPassword.value.length >= 8 && confirmPassword.value.length <= 24) {
          passwordTwoLengthErr = false
        } 
        // Password is not the right length
        else {
          passwordTwoLengthErr = true
        }
      }

      if(passwordOneLengthErr === true || passwordTwoLengthErr === true) {
        passwordLengthErr.value = true
        newPasswordValidated.value = false
      }
      if(passwordOneLengthErr === false && passwordTwoLengthErr === false) {
        passwordLengthErr.value = false
      }

      // Passwords match
      if(newPassword.value === confirmPassword.value) {
        passwordMismatch.value = false

        if(passwordLengthErr.value === false) {
          //store
          newPasswordValidated.value = true
        } else {
          newPasswordValidated.value = false
        }
      } 
      // Passwords don't match
      else {
        newPasswordValidated.value = false
        passwordMismatch.value = true
      }
    }

    function storeNewPassword() {
      presentLoading()
      apiClient.post('/api/update/password', 
        {
          password: newPassword.value           
        },
        {
          headers: {
            'Authorization':  `Bearer ${props.store.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            toastMessage.value = 'You have updated your password.'
            this.dataUp()
            openToast()
            resetForm()
          }
          // Fail Validation
          if(response.data.message == 'fail') {
            toastMessage.value = 'Something went wrong.'
            openToast()          
          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'Something went wrong.'
            openToast()
            isError.value = true
            errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in to update your profile.'
            openToast()
          }
        }).catch(error => {
          console.log(error)  
          // credentials didn't match

          isError.value = true
          errors.value = error.response.data.errors

        });
    }

    return {
      currentPassword, newPassword, confirmPassword, currentPasswordValidated, validateCurrentPassword, currentPasswordFailed, resetForm, newPasswordValidated, validateNewPassword, storeNewPassword, passwordMismatch, passwordLengthErr, presentLoading, isError, errors
    }
  },
  methods: {
    dataUp: function() {
      this.$emit('component-data', 'go-back')
    }
  }, 
  watch: {
    newPassword: function() {
      this.validateNewPassword()
    },
    confirmPassword: function() {
      this.validateNewPassword()
    }
  }
});
</script>